
import { computed, defineComponent, reactive, ref, watch } from '@vue/composition-api'
import { useReportingRepository } from '@/api/repository/reporting-repository'
import { useAuthGetters } from '@/store'
import { useGetUsers, User } from '@/api/users'
import { Rights } from '@/api/rights'
import ReportingTable from '@/components/reporting/reporting-table.cmp.vue'
import Chart from '@/components/common/chart.cmp.vue'
import { ReportingByMonthAndProject } from '@/api/interfaces/reporting'
import { ChartConfiguration } from 'chart.js'
import { useStringColor } from '@/utils/string-color'
import { debounce } from 'lodash-es'

export default defineComponent({
  name: 'reporting-overview',
  components: {
    ReportingTable,
    Chart,
  },
  setup(_, { root }) {
    const reportingRepository = useReportingRepository()
    const { getMonthly, data } = reportingRepository.useGetMonthly()
    const { currentUser, hasRight } = useAuthGetters()
    const filters = reactive({
      year: new Date().getFullYear(),
      userId: currentUser.value.id,
    })

    const { getUsers, data: users } = useGetUsers()
    const userSearchInput = ref('')
    const debounceUserSearch = debounce((name) => getUsers({ page: 0, size: 20 }, { name, active: true }), 500)
    watch(userSearchInput, (name) => debounceUserSearch(name))

    const years = computed(() => {
      const years: { text: number; value: number }[] = []
      const currentYear = new Date().getFullYear()
      for (let i = 2017; i <= currentYear; i++) {
        years.push({ text: i, value: i })
      }
      return years
    })

    async function updateReportings() {
      getMonthly({ params: { ...filters } })
    }

    const canSearchUsers = hasRight.value(Rights.REPORTING_READ_ALL)

    interface ChartItem {
      projectId: number
      projectName: string
      month: string
      bookedHours: number
    }

    const months = [
      root.$t('months.january').toString(),
      root.$t('months.february').toString(),
      root.$t('months.march').toString(),
      root.$t('months.april').toString(),
      root.$t('months.may').toString(),
      root.$t('months.june').toString(),
      root.$t('months.july').toString(),
      root.$t('months.august').toString(),
      root.$t('months.september').toString(),
      root.$t('months.october').toString(),
      root.$t('months.november').toString(),
      root.$t('months.december').toString(),
    ]

    const { getColor } = useStringColor()

    // TODO: fix ts
    const barChartConfig = computed(() => {
      const entries =
        data.value
          ?.map((month) => {
            return month.reportingByMonthAndProjects.reduce((reportings, reporting) => {
              const project = reportings[reporting.project] || {
                projectId: reporting.project,
                projectName: reporting.projectName,
                month: months[month.month - 1],
                bookedHours: 0,
              }
              project.bookedHours += reporting.bookedHours
              reportings.push(project)
              return reportings
            }, [] as ChartItem[])
          })
          .flat()
          .reduce((datasets, entry) => {
            let item = datasets[entry.projectId]
            if (!item) {
              item = {
                label: entry.projectName,
                data: [],
                backgroundColor: getColor(entry.projectName),
              }
              datasets[entry.projectId] = item
            }
            item.data.push(entry)
            return datasets
          }, {}) || []

      return {
        type: 'bar',
        data: {
          labels: months,
          datasets: Object.values(entries),
        },
        options: {
          parsing: {
            xAxisKey: 'month',
            yAxisKey: 'bookedHours',
          },
          scales: {
            x: {
              stacked: true,
              grid: {
                display: false,
                // color: getComputedStyle(document.documentElement).getPropertyValue('--v-onPrimary-base'),
              },
            },
            y: {
              stacked: true,
              grid: {
                display: false,
                // color: getComputedStyle(document.documentElement).getPropertyValue('--v-onPrimary-base'),
              },
            },
          },
          maintainAspectRatio: false,
        },
      }
    })

    const doughnutChartConfig = computed<ChartConfiguration>(() => {
      const projects = data.value?.reduce((projects, entry) => {
        const newItems = entry.reportingByMonthAndProjects.map((item) => item.projectName)
        const items = [...new Set([...projects, ...newItems])]
        return items
      }, [] as any[])

      const projectHours =
        data.value
          ?.reduce((values, entry) => {
            values.push(...entry.reportingByMonthAndProjects)
            return values
          }, [] as ReportingByMonthAndProject[])
          .reduce((projectHours, entry) => {
            if (!projectHours[entry.projectName]) projectHours[entry.projectName] = 0
            projectHours[entry.projectName] += entry.bookedHours
            return projectHours
          }, {}) || []

      return {
        type: 'doughnut',
        data: {
          labels: projects,
          datasets: [
            {
              data: Object.values(projectHours),
              backgroundColor: projects?.map((project) => getColor(project)),
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: 'bottom',
            },
          },
        },
      }
    })

    const getUserFullName = (user: User) => {
      return `${user.firstName} ${user.lastName}`
    }

    watch(
      filters,
      () => {
        updateReportings()
      },
      {
        immediate: true,
      }
    )

    watch(
      () => data,
      (value) => {
        if (value == null) return
      }
    )

    return {
      data,
      filters,
      updateReportings,
      years,
      users,
      canSearchUsers,
      barChartConfig,
      doughnutChartConfig,
      userSearchInput,
      getUserFullName,
    }
  },
})
