
import { defineComponent, onMounted, PropType, watchEffect } from '@vue/composition-api'
import { ChartConfiguration } from 'chart.js'
import { uniqueId } from 'lodash-es'
import Chart from 'chart.js/auto'

export interface ChartData {
  labels: string[]
  datasets: any[]
  backgroundColor: string[] | string
}

export default defineComponent({
  name: 'chart',
  props: {
    config: {
      type: Object as PropType<ChartConfiguration>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    let chart: Chart | null = null
    const id = uniqueId('chart-')

    onMounted(() => {
      watchEffect(() => {
        if (props.loading) return
        chart?.destroy()
        chart = new Chart(id, { ...props.config })
      })
    })

    return {
      chart,
      id,
    }
  },
})
