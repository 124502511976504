
import { Reporting } from '@/api/interfaces/reporting'
import { useFormat } from '@/utils/format'
import { defineComponent, PropType } from '@vue/composition-api'
import { DataTableHeader } from 'vuetify'

export default defineComponent({
  name: 'reporting-table',
  props: {
    items: {
      type: Array as PropType<Reporting[]>,
      required: true,
    },
    create: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Function as PropType<(reporting: Reporting) => boolean>,
      default: () => false,
    },
    deletable: {
      type: Function as PropType<(reporting: Reporting) => boolean>,
      default: () => false,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { root }) {
    const tableHeaders: DataTableHeader[] = [
      {
        text: root.$t('date.month').toString(),
        value: 'month',
      },
      {
        text: root.$t('reporting.hoursDone').toString(),
        value: 'hoursDone',
      },
      {
        text: root.$tc('project.title', 2).toString(),
        value: 'reportingByMonthAndProjects',
      },
      // Disabled for now
      // {
      //   text: root.$t('reporting.productivity').toString(),
      //   value: 'billableRatio',
      // },
      {
        text: root.$t('reporting.timeAccount').toString(),
        value: 'timeAccount',
      },
    ]

    function pad(value: number) {
      if (value < 10) return `0${value}`
      return value
    }

    return {
      tableHeaders,
      pad,

      ...useFormat(),
    }
  },
})
