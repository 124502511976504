import { useAxios } from '@/plugins/axios'
import { repository } from '@/plugins/repository'
import { AxiosRequestConfig } from 'axios'
import { ReportingByMonth } from '../interfaces/reporting'

export const useReportingRepository = () => {
  const version = 'v1'
  const resource = 'reportings'
  const reportingRepository = repository<ReportingByMonth, ReportingByMonth>(version, resource)

  const useGetMonthly = () => {
    const client = useAxios<ReportingByMonth[]>({
      method: 'get',
      url: `${version}/${resource}/monthly`,
    })

    const getMonthly = (cfg: AxiosRequestConfig = {}) => client.exec({ ...cfg })

    return {
      ...client,
      getMonthly,
    }
  }

  return {
    ...reportingRepository,
    useGetMonthly,
  }
}
