export const useStringColor = () => {
  const getColor = (stringInput: string) => {
    const stringUniqueHash = [...stringInput].reduce((acc, char) => {
      return char.charCodeAt(0) + ((acc << 5) - acc)
    }, 0)
    return `hsl(${stringUniqueHash % 360}, 95%, 55%)`
  }
  return {
    getColor,
  }
}
