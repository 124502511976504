var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._g(_vm._b({attrs:{"headers":_vm.tableHeaders,"items":_vm.items},scopedSlots:_vm._u([{key:"item.month",fn:function(ref){
var ref_item = ref.item;
var year = ref_item.year;
var month = ref_item.month;
return [_vm._v(" "+_vm._s(((_vm.pad(month)) + "." + year))+" ")]}},{key:"item.hoursDone",fn:function(ref){
var ref_item = ref.item;
var accumulatedHours = ref_item.accumulatedHours;
var targetHours = ref_item.targetHours;
return [_vm._v(" "+_vm._s(((_vm.formatDecimal(accumulatedHours - targetHours)) + " (" + (_vm.formatDecimal(accumulatedHours)) + " / " + (_vm.formatDecimal( targetHours )) + ")"))+" ")]}},{key:"item.reportingByMonthAndProjects",fn:function(ref){
var reportingByMonthAndProjects = ref.item.reportingByMonthAndProjects;
return [_c('ul',_vm._l((reportingByMonthAndProjects),function(ref){
var bookedHours = ref.bookedHours;
var project = ref.project;
var projectName = ref.projectName;
var billable = ref.billable;
return _c('li',{key:("project-" + project + "-" + billable),class:{ 'non-billable': !billable }},[_vm._v(" "+_vm._s(_vm.formatDecimal(bookedHours))+" "+_vm._s(_vm.$t('units.time.hour'))+" "+_vm._s(projectName)+" ")])}),0)]}},{key:"item.billableRatio",fn:function(ref){
var billableRatio = ref.item.billableRatio;
return [_vm._v(" "+_vm._s(_vm.formatDecimal(billableRatio * 100))+" % ")]}},{key:"item.timeAccount",fn:function(ref){
var timeAccount = ref.item.timeAccount;
return [_vm._v(" "+_vm._s(_vm.formatDecimal(timeAccount))+" "+_vm._s(_vm.$t('units.time.hour'))+" ")]}}],null,true)},'v-data-table',_vm.$attrs,false),_vm.$listeners))}
var staticRenderFns = []

export { render, staticRenderFns }